.dashboard-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
  min-width: min-content;
}

.dashboard-card {
  display: flex;
  border-radius: 10px;
  background-color: #d9d9d9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  min-width: 300px;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  box-sizing: border-box;
}

.pane-row-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: min-content;
  margin-bottom: 2vh;
  flex-wrap: wrap;
  gap: 20px;
  box-sizing: border-box;
}

.secular-one-regular {
  font-family: "Secular One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.title {
  font-size: 32px;
}

.subtitle {
  font-size: 26px;
}

.semi-subtitle {
  font-size: 22px;
}

.important-number {
  font-size: 28px;
  color: rgb(15, 123, 13);
}

.normal-text {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  font-variation-settings: "wdth" 300;
}
