.page-container {
  background-color: #fff;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  position: relative;
}

.dashboard-container {
  all: unset;
  display: flex;
  flex: 1;
  overflow: visible;
  position: relative;
  min-width: min-content;
}

.tabContainer {
  flex: 1;
  overflow: visible;
  display: flex;
  min-width: min-content;
}

.dashboard-content {
  flex: 4;
  text-align: right;
  align-items: flex-end;
  align-self: flex-start;
  align-content: flex-start;
  overflow: visible;
  padding: 20px;
  min-width: min-content;
}

.dashboard-nav-pane {
  flex: 1;
  background-color: #efefef;
  padding: 15px;
  margin-left: 30px;
  overflow: visible;
  position: sticky;
  top: 0;
  height: 100vh;
  min-width: 350px;
}

.homepane {
  padding: 20px;
  margin-top: 20px;
}

.nav-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.nav-item {
  display: flex;
  align-items: flex-end;
  text-align: right;
  flex-direction: row-reverse;
  margin-top: 2vh;
}

.nav-link {
  color: black;
  display: flex;
  flex-direction: row-reverse;
  justify-items: flex-end;
  align-items: center;
  width: 300px;
  font-weight: bold;
  font-size: larger;
}

.nav-link.active {
  color: white !important;
  background-color: rgb(3, 166, 0) !important;
}

.nav-link:hover {
  color: rgb(3, 166, 0) !important;
}

.logout-nav-link.active {
  color: white !important;
  background-color: rgb(187, 4, 4) !important;
}

.logout-nav-link:hover {
  color: rgb(187, 4, 4) !important;
}

.icon {
  margin-left: 10px;
}

.pane {
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logout-button {
  color: red;
  border: none;
  background-color: transparent;
  transition: color 0.3s;
  border-radius: 50%;
  padding: 10px;
  margin-left: 30vh;
}

.logout-button.hovered {
  color: darkred;
}

.close-button {
  font-size: 2rem;
  /* Adjust the size as needed */
}

.rocket-icon {
  font-size: 32px;
  margin-right: 10px;
}

.text {
  font-size: 24px;
  color: black;
}

.dashboard-nav {
  display: flex;
  justify-content: flex-end;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 10px;
  text-align: right;
}

.dashboard-nav .nav-link {
  color: #333;
  font-weight: 600;
  margin-left: 10px;
  border-radius: 8px;
  transition: background-color 0.3s;
  text-align: right;
}

.dashboard-content h2 {
  color: #333;
  margin-bottom: 20px;
  text-align: right;
}

.event-card {
  background-color: #52a0e9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.eventFormHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eventFormInput {
  text-align: right;
  direction: rtl;
}

.eventFormFieldContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.eventFormHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.eventFormHeader .close-button {
  font-size: 2rem;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-left: 20px;
}

.eventsHeaderContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.coachCard {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: yellow;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 15px;
}

.coachDetails {
  flex-grow: 1;
}

.coachName {
  margin-bottom: 5px;
  font-weight: bold;
}

.coachPhoneNumber {
  margin-bottom: 5px;
}

.coachActions {
  display: flex;
  flex-direction: row;
}

.edit-button,
.delete-button {
  margin-left: 5px;
}

.close-button {
  font-size: 2rem;
}

.hebrewText {
  direction: rtl;
  text-align: right;
}

.hebrewHeader {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
}

.groupsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 20px;
}

.tableContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 20px;
}

.groupCard {
  padding: 10px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
  direction: rtl;
  background-color: rgb(144, 234, 144);
  border-radius: 5px;
}

.groupName {
  font-weight: bold;
}

.groupClubName {
  margin-top: 5px;
}

.groupCoach {
  margin-top: 5px;
}

.practiceDay {
  margin-top: 10px;
}

.day {
  font-weight: bold;
}

.hours {
  margin-top: 5px;
}

.groupFormModal .toggleDaysContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.groupFormModal .toggleDayButton {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.groupFormModal .toggleDayButton.active {
  background-color: #007bff;
  color: #fff;
}

.groupFormHeader {
  display: flex;
  justify-content: space-between;
}

.groupFormFieldContainer {
  margin-bottom: 20px;
  text-align: right;
}

.groupFormInput {
  width: 100%;
}

.toggleDaysContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.toggleDayButton {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.toggleDayButton.active {
  background-color: #007bff;
  color: #fff;
}

.timeRangeContainer {
  margin-top: 20px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.groupsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.groupCard {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.groupCard.expanded {
  background-color: #f0f0f0;
}

.groupHeader {
  display: flex;
  flex-direction: column;
}

.groupName {
  font-weight: bold;
}

.groupClubName {
  margin-top: 5px;
}

.groupCoach {
  margin-top: 5px;
}

.practiceDay {
  margin-top: 10px;
}

.day {
  font-weight: bold;
}

.hours {
  margin-top: 5px;
}

.helpHeader {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
}
